import React from 'react';
import { Link } from 'gatsby';
import Logo from '../images/logo.png';

const navigation = [
  // { name: 'Home', href: '/' },
  { name: 'Meet Gayann', href: '/biography' },
  { name: 'Platform', mobile: 'Platform', href: '/issues' },
  // { name: 'Gallery', href: '/gallery' },
  { name: 'Contact', mobile: 'Contact', href: '/contact' },
  { name: 'Donate', mobile: 'Donate', href: '/donate' },
  { name: 'Where To Vote', mobile: 'Vote', external: true, href: 'https://idahovotes.gov/vote-early-idaho/' },]

function Header() {
  return (
    <header className="bg-blue-900">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-blue-100 lg:border-none">
          <div className="flex items-center">
            <Link to="/" className="">
              <span className="sr-only">Gayann DeMordaunt</span>
              <img
                className="h-20 sm:h-10 w-auto"
                src={Logo}
                alt="Gayann DeMordaunt Logo"
              />
            </Link>
            <div className="hidden ml-10 space-x-8 lg:block">
              {navigation.map((link) => {
                if (link.external) {
                  return (
                    <a
                      key={link.name}
                      href={link.href}
                      target="_blank"
                      rel="noreferrer"
                      className="text-base font-medium text-white hover:text-indigo-50"
                    >
                      {link.name}
                    </a>
                  )
                } else {
                  return (
                    <Link key={link.name} to={link.href} className="text-base font-medium text-white hover:text-indigo-50">
                      {link.name}
                    </Link>
                  )
                }
              })}
            </div>
          </div>
          <div className="hidden sm:block ml-10 space-x-4">
            <a href="https://www.facebook.com/gayanndemordaunt" target="_blank" rel="noreferrer">
              <svg fill="currentColor" viewBox="0 0 24 24" className="h-7 w-7 text-blue-50 hover:text-blue-100">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map((link) => {
            if (link.mobile) {
              if (link.external) {
                return (
                  <a
                    key={link.name}
                    href={link.href}
                    className="text-base font-medium text-white hover:text-indigo-50"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.mobile}
                  </a>
                )
              } else {
                return (
                  <Link key={link.name} to={link.href} className="text-base font-medium text-white hover:text-indigo-50">
                    {link.mobile}
                  </Link>
                )
              }
            } else return null
          })}
        </div>
      </nav>
    </header>
  )
}

export default Header