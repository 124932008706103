import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';

const navigation = {
  links: [
    { name: 'Where To Vote', href: 'https://idahovotes.gov/vote-early-idaho/' },
    { name: 'Request Absentee Ballot', href: 'https://idahovotes.gov/vote-early-idaho/' },
    { name: 'Idaho Legislature', href: 'http://legislature.idaho.gov/' },
  ],
  support: [
    { name: 'Gallery', href: '/gallery' },
    { name: 'Volunteer', href: '/contact' },
    { name: 'Request Yard Sign', href: '/contact' },
    { name: 'Donate', href: '/donate' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/gayanndemordaunt',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}


function Footer() {
  return (
    <footer className="bg-blue-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <StaticImage
              className="w-40"
              src="../images/logo.png"
              alt="Gayann DeMordaunt"
            />
            <p className="text-blue-50 text-base">
              Lower taxes on Idaho families and businesses, reduce property taxes, and cut government spending.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-blue-50 hover:text-blue-300">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 xl:col-span-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-red-600 tracking-wider uppercase">Links</h3>
                <ul className="mt-4 space-y-4 link-list">
                  {navigation.links.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} rel="noreferrer" target="_blank" className="text-base text-blue-50 hover:text-blue-300">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-red-600 tracking-wider uppercase">Support Gayann</h3>
                <ul className="mt-4 space-y-4 link-list">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-base text-blue-50 hover:text-blue-300">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-blue-50 pt-8">
          <p className="text-sm text-blue-50 xl:text-center">&copy; Paid for by Committee to Elect Gayann DeMordaunt - Greg Call, Treasurer</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;